.event-type-container{
    width: 300px;
    height: 225px;
    border: 2px solid rgb(200, 200, 200);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 2rem;
}

.event-type-selected{
    width: 300px;
    height: 225px;
    border: 2px solid green;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 2rem;
}

.event-type-container > p, .event-type-selected > p{
    font-weight: bold;
}

.event-type-container:hover, .event-type-selected:hover{
    cursor: pointer;
}

.step-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.set-container{
    width: 100%;;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin: 1rem 0;
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
    padding: 10px 20px;
}


.date-input{
    width: 200px;
    height: 50px;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid rgb(200, 200, 200);
}

#confirm-set-btn{
    background-color: rgb(230, 230, 230);
    border: unset;
    padding: 10px;
    box-sizing: border-box;
    width: 150px;
    height: 40px;
    border-radius: 20px;
    font-weight: bold;
    display: block;
    margin: 1rem auto;
}

.set-time{
    width: 200px;
    height: 50px;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid rgb(200, 200, 200);
    margin: 0 10px;
}

#event-roster-select{
    display: block;
    margin: 1rem auto;
    box-sizing: border-box;
    height: 50px;
    width: 450px;
    border: 2px solid rgb(200, 200, 200);
    border-radius: 10px;
    padding: 10px;

}

#summary-table{
    width: 100%;
    margin-top: 2rem;
    border-collapse: collapse;

}

#summary-table tr{
    border: 1px solid grey;
}

#summary-table th{
    border-right: 1px solid grey;
}

#summary-table th, #summary-table td{
padding: 1rem;}