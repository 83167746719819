#add-roster-btn{
    width: 200px;
    height: 40px;
    border-radius: 20px;
    border: unset;
    font-weight: bold;
    font-size: 1.05rem;
    transition:0.5s ;
}

#add-roster-btn:hover{
    cursor: pointer;
    background-color: rgb(170, 170, 170);
    color: white;
}



.roster-img{
    height: 60px;
    width: 60px;
    border-radius: 30px;
    margin: 10px;
}

.roster-artist-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap ;
    width: 100%;
}

.roster-container{
    display: flex;
    flex-direction: column;
    background-color: rgb(240, 240, 240);
    padding: 20px;
    border-radius: 10px;
    margin: 2rem 0;
}

.roster-artist{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
}