.category-container{
    width: 95%;
    height: auto;
    background-color: rgb(240, 240, 240);
    padding: 20px;
    margin: 2rem auto;
}

.artist-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel-img{
    height: 225px;
    width: 20vw;
    object-fit: cover;
    object-position: center;
    margin: 0 auto;
}

.artist-btn{
    background-color: #1e1e1e;
    color: white;
    height: 40px;
    border-radius: 20px;
    width: 150px;
    border: unset;
    display: block;
    margin: 10px auto;
    transition:0.5s ;
}

.artist-btn:hover{
    cursor: pointer;
    background-color: #3c3c3c;
}

.add-to-roster-btn{
    background-color: rgb(240, 240, 240);
    color: #1e1e1e;
    height: 40px;
    border-radius: 20px;
    width: 150px;
    border: 1px solid #1e1e1e;
    display: block;
    margin: 0px auto;
    transition:0.5s ;
}

.add-to-roster-btn:hover{
    cursor: pointer;
}

#modal-open{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 50vh;
    z-index: 999;
    position: absolute;
    top: 25vh;
    left: 25vw;
    background-color: white;
}

#modal-closed{
    display: none;
}

#roster-select{
    width: 50%;
    margin: 10px auto;
    height: 50px;
    padding: 5px;
    border-radius: 30px;
}

.close-modal{
    border: 1px solid #1e1e1e;
    padding: 5px;
    height: 30px;
    width: 150px;
    border-radius: 20px;
    margin: 2rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
}