

/* The side navigation menu */
.sidenav-closed {
    height: 100vh; /* 100% Full-height */
    width: 40px; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #1e1e1e; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    display: flex;
    flex-direction: column;
  }

  .sidenav-open {
    height: 100vh; /* 100% Full-height */
    width: 250px; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #111; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
  }
  
  

  .sidenav-closed a {
    display: none;
  }
  
  
  /* When you mouse over the navigation links, change their color */
  .sidenav-open a:hover {
    color: #f1f1f1;
  }
  
  
  /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
  #main-closed {
    transition: margin-left .5s;
    padding: 20px;
    margin-left: 40px ;
  }

  #main-open{
      margin-left: 250px ;
      transition: margin-left .5s;
      padding: 20px;

  }

  #logout-btn{
      width: 80%;
      height: 40px;
      margin: 2rem auto;
      border: unset;
      border-radius: 20px;
      font-weight: bold;
  }

  #logout-btn:hover{
    cursor: pointer;
}

#nav-logo{
  width: 75px;
  margin: 0 auto 30px auto;
}

.nav-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 10px;
  text-decoration: none;

}

.nav-item:hover{
  cursor: pointer;
}

.nav-title{
  font-family: -apple-system-headline, BlinkMacSystemFont, sans-serif;
  margin-left: 2rem;
  color: white;
  font-weight: bold;
  
}
  
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }