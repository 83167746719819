#settings-container{
    width: 70%;
    max-width: 800px;
    margin: 5% auto;
    text-align: center;
}

#company-logo{
    height: 150px;
    width: 150px;
    object-position: center;
    object-fit: contain;
    border-radius: 75px;
}