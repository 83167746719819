#login-page, #signup-page{
    background-color: #1e1e1e;
    height: 100vh;
    width: 100vw;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}
.login-form{
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 500px;
    height: 600px;
    margin: 2rem auto 0 auto;
}

.login-input{
    border: unset;
    background-color: unset;
    border-bottom: 2px solid white;
    padding: 15px;
    margin: 10px;
    width: 80%;
    font-size: 1.2rem;
    color: white;
}

.login-input::placeholder{
    font-weight: bold;
    font-size: 1.05rem;
    color: white;
}

.auth-btn{
    width: 60%;
    height: 50px;
    border-radius: 25px;
    border: unset;
    font-weight: bold;
    font-size: 1.05rem;
    margin: 4rem 0 2rem 0;
    transition: 0.5s;
}

.auth-btn:hover{
    background-color: rgb(100, 100, 100);
    cursor: pointer;
    color: white;
}

.auth-page-title{
    margin-bottom: 4rem;
}
.info{
    font-weight: bold;
}

.auth-logo{
    height: 150px;
    width: 150px;
}